import React, { forwardRef } from 'react';
import { Grid, Button, FormControl, TextField, Checkbox, FormControlLabel, FormLabel, RadioGroup, Radio } from '@material-ui/core';
import WaitingScreen from '../../custom/backdrop';
import { findLatLang } from '../../../helpers/google';
import InputAutocomplete from '../../custom/autocomplete';
import InputAutocompleteRelated from '../../custom/autocompleteRelatedRequest';
import ModalSelectServices from './modalService';
import AlertDialog from '../../alert-dialog';
import store from '../../../redux/store';
import { alertActions } from '../../../redux/actions/alert_actions';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import setYear from "date-fns/setYear";
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
import "react-datepicker/dist/react-datepicker.css";
import i18next from 'i18next';
import { history } from '../../../helpers/history';
import { tripsService } from '../../../services/tripsService';
import ModalExpeditions from './modalExpeditions';
import { getOriginRequestTrip, getDestinationRequestTrip, getPickUpTimeRequestTrip, getPassengersRequestTrip, getLuggageRequestTrip, getWheelChairRequestTrip, getIsOnRelatedCustomerBehalf, getRelatedCustomer, isReturn, getOriginalId, } from '../tools';
import { factory } from '../../../helpers/factory';
import { SelectRequestType } from './components/selectRequestType';
import "./styles.css";
const ORIGIN = 0;
const DESTINATION = 1;
class RequestTripForm extends React.Component {
    constructor(props) {
        super();
        const { tripReducer, customerReducer } = props

        this.state = {
            originAddress: getOriginRequestTrip(tripReducer),
            destinationAddress: getDestinationRequestTrip(tripReducer),
            pickupTime: getPickUpTimeRequestTrip(tripReducer),
            passengers: getPassengersRequestTrip(tripReducer),
            luggage: getLuggageRequestTrip(tripReducer),
            babyCart: false,
            userWheelChairSelection: getWheelChairRequestTrip(tripReducer, customerReducer),
            wheelchair: getWheelChairRequestTrip(tripReducer, customerReducer),
            isOnRelatedCustomerBehalf: getIsOnRelatedCustomerBehalf(tripReducer),
            relatedCustomer: getRelatedCustomer(tripReducer),
            isReturn: isReturn(tripReducer),
            originalTripId: getOriginalId(tripReducer),
            region: null,
            bounds: null,
            showingInfoWindow: false,
            markers: [],
            ticked: false,
            timeText: '',
            destinationsZones: [],
            isRequestByDropOff: false,
        }
        this.geoCodeAddress = this.geoCodeAddress.bind(this);
        this.handleRequestTrip = this.handleRequestTrip.bind(this);
        this.setOriginAndDestination = this.setOriginAndDestination.bind(this)
    }

    componentDidMount() {
        const { tripReducer } = this.props;
        if (this.state.originAddress != '' && this.state.destinationAddress != '') {
            const markers = [...this.state.markers];
            if (tripReducer.repeatDataObject != null) {
                markers[ORIGIN] = { position: { 'lat': tripReducer.repeatDataObject.pickUpLocation.location.lat, 'lng': tripReducer.repeatDataObject.pickUpLocation.location.lng }, name: ORIGIN };
                markers[DESTINATION] = { position: { 'lat': tripReducer.repeatDataObject.dropOffLocation.location.lat, 'lng': tripReducer.repeatDataObject.dropOffLocation.location.lng }, name: DESTINATION };
            }
            if (tripReducer.isModifyTrip && tripReducer.modifyTripData != null) {
                markers[ORIGIN] = { position: { 'lat': tripReducer.modifyTripData.pickUpLocation.lat, 'lng': tripReducer.modifyTripData.pickUpLocation.lon }, name: ORIGIN };
                markers[DESTINATION] = { position: { 'lat': tripReducer.modifyTripData.dropOffLocation.lat, 'lng': tripReducer.modifyTripData.dropOffLocation.lon }, name: DESTINATION };
            }
            this.props.setMarkers(markers);
            this.setState({
                markers
            }, () => this.bounds());
        }
        registerLocale('es', es)
        registerLocale('en', en)
        registerLocale('ca', ca)
        switch (true) {
            case (i18next.language == "en"):
                //registerLocale('en', en)
                this.state.timeText = "Time"
                break;
            case (i18next.language == "es"):
                //registerLocale('es', es)
                this.state.timeText = "Hora"
                break;
            case (i18next.language == "ca"):
                //registerLocale('ca', ca)
                this.state.timeText = "Hora"
                break;
            default:
                //registerLocale('ca', ca)
                setDefaultLocale('ca')
                break;
        }
    }

    setOriginAndDestination(zoneId, zoneName, point) {
        tripsService.getDestinationZones(zoneId).then(
            results => {
                let resultArray = []
                results.forEach(elementZone => {
                    resultArray.push({
                        description: elementZone.name,
                        address: elementZone.stops[0]?.address,
                        location: elementZone.stops[0]?.point,
                        type: 'destinationZoneAddress',
                        matched_substrings: [{
                            length: elementZone.name.length,
                            offset: 0,
                        }],
                        structured_formatting: {
                            main_text: elementZone.name.split(',')[0].trim(),
                            main_text_matched_substrings:
                                [{
                                    length: elementZone.name.length,
                                    offset: 0
                                }]
                        }
                    })
                });
                const markers = [...this.state.markers];
                if (resultArray.length === 1) {
                    markers[ORIGIN] = {
                        hidden: true,
                        position: { lat: point.lat, lng: point.lon },
                        name: ORIGIN
                    }
                    markers[DESTINATION] = {
                        hidden: true,
                        position: { lat: resultArray[0].location?.lat, lng: resultArray[0].location?.lon },
                        name: DESTINATION
                    }
                    this.state.originAddress = zoneName
                    this.state.destinationAddress = resultArray[0].description
                    this.state.destinationsZones = resultArray
                    this.state.markers = markers
                    this.bounds()
                }
                else {
                    markers[ORIGIN] = {
                        hidden: true,
                        position: { lat: point.lat, lng: point.lon },
                        name: ORIGIN
                    }
                    markers[DESTINATION] = null;
                    this.state.originAddress = zoneName
                    this.state.destinationsZones = resultArray
                    this.state.destinationAddress = this.state.destinationAddress !== '' ? '' : this.state.destinationAddress
                    this.state.markers = markers

                    this.bounds()
                }
                this.props.setMarkers(markers);
            })
    }




    async geoCodeAddress(address, index) {
        if (address != null) {
            //let coords = await geoCode.coordsFromAddress(address.description); //OLD GETCOORDS -> DISABLED NOW
            let locationLatLng = null;
            if (address.type != 'destinationZoneAddress') {
                try {
                    locationLatLng = await findLatLang(address.description);
                } catch (e) {
                    locationLatLng = null;
                }
            } else {
                locationLatLng = [address.location.lat, address.location.lon]
            }


            const lat = locationLatLng != null ? locationLatLng[0] : null;//coords.lat;
            const lng = locationLatLng != null ? locationLatLng[1] : null;//coords.lng;
            this.setState(prevState => {
                const markers = [...this.state.markers];
                markers[index] = { ...markers[index], position: { lat, lng }, name: index, hidden: address.type == 'destinationZoneAddress' };
                this.props.setMarkers(markers);
                return { markers };
            });

            this.setState({
                showingInfoWindow: false,
                region: true
            });

            if (index == DESTINATION) {
                this.setState({
                    destinationAddress: address.description
                });
                window.selectAutoCompleteDestination = false
                if (this.state.markers[ORIGIN] != null) {
                    if (this.state.markers[ORIGIN].position.lat != null && this.state.markers[ORIGIN].position.lng != null) {
                        this.bounds();
                    }
                }
            } else {
                this.setState({
                    originAddress: address.description
                });
                if (this.state.markers[DESTINATION] != null) {
                    if (this.state.markers[DESTINATION].position.lat != null && this.state.markers[DESTINATION].position.lng != null) {
                        this.bounds();
                    }

                } else {
                    this.bounds('origin');
                }
                window.selectAutoCompleteOrigin = false
            }
        } else {
            this.setState(prevState => {
                const markers = [...this.state.markers];
                markers[index] = null;
                this.props.setMarkers(markers);
                return { markers };
            });
            this.setState({
                region: false,
            });
        }
    }

    bounds(args) {
        let bounds = new this.props.google.maps.LatLngBounds();
        if (args == 'origin') {
            let points = [
                { lat: this.state.markers[ORIGIN].position.lat, lng: this.state.markers[ORIGIN].position.lng }
            ]
            for (const element of points) {
                bounds.extend(element);
            }
        } else {
            let points = [
                { lat: this.state.markers[ORIGIN].position.lat, lng: this.state.markers[ORIGIN].position.lng },
                { lat: this.state.markers[DESTINATION]?.position?.lat, lng: this.state.markers[DESTINATION]?.position?.lng },
            ]
            for (const element of points) {
                if (element.lat && element.lng)
                    bounds.extend(element);
            }
        }
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
            let offset = 0.002;
            let center = bounds.getCenter();
            bounds.extend(new this.props.google.maps.LatLng(center.lat() + offset, center.lng() + offset));
            bounds.extend(new this.props.google.maps.LatLng(center.lat() - offset, center.lng() - offset));
        }

        this.setState({
            bounds
        });
        this.props.setBounds(bounds);
    }

    handleRequestTrip() {
        //console.log("this.state.markers => ", this.state.markers)
        const { t, tripReducer, customerReducer } = this.props
        this.setState({ ticked: true })
        if ((this.state.markers[ORIGIN] == null && this.props.originStop == '') || (this.state.markers[DESTINATION] == null && this.props.destinationStop == '')) {
            store.dispatch(alertActions.error((t('form.requestTripForm.mustSelectOriginAndDestination'))));
        } else if (this.state.pickupTime == null) {
            store.dispatch(alertActions.error((t('form.requestTripForm.mustEnterPickUpTime'))));
        } else if (this.state.passengers == "") {
            store.dispatch(alertActions.error((t('form.requestTripForm.numPassengersNotVoid'))));
        } else if (this.state.passengers < 1) {
            store.dispatch(alertActions.error((t('form.requestTripForm.numPassengerInvalid'))));
        } else if (this.state.luggage === "") {
            store.dispatch(alertActions.error((t('form.requestTripForm.numLuggageNotVoid'))));
        } else if (this.state.luggage < 0) {
            store.dispatch(alertActions.error((t('form.requestTripForm.numLuggageInvalid'))));
        } else if (this.state.isOnRelatedCustomerBehalf == true && this.state.relatedCustomer == null) {
            store.dispatch(alertActions.error((t('form.requestTripForm.errorRelatedCustomer'))));
        }
        else {
            let customerId = customerReducer.currentUser.id;
            let customerName = (customerReducer.currentUser.name + ' ' + customerReducer.currentUser.surname1);
            let customerDateOfBirth = customerReducer.currentUser.birthDate || null;
            let serviceName = null;
            let pushToken = null;
            if (tripReducer.isModifyTrip == true) {
                const obj = {
                    origin: tripReducer.modifyTripData.pickUpLocation,
                    destination: tripReducer.modifyTripData.dropOffLocation,
                    isRequestByDropOff: this.state.isRequestByDropOff,
                    numPassengers: parseInt(this.state.passengers),
                    requestPickUpStartTime: this.state.pickupTime,
                    customerName: customerName.trim(),
                    hasWheelChair: this.state.wheelchair,
                    // babyCart: this.state.babyCart,
                    luggage: parseInt(this.state.luggage),
                    serviceId: tripReducer.modifyTripData.serviceId,
                    pushToken: pushToken,
                    customerDateOfBirth: customerDateOfBirth,
                    serviceName: serviceName,
                    isOnRelatedCustomerBehalf: this.state.isOnRelatedCustomerBehalf,
                    relatedCustomer: this.state.relatedCustomer,
                    passengerId: tripReducer.modifyTripData.passengerId,
                }
                this.props.modifyTrip(obj);
            } else {
                const origin = this.state.markers[ORIGIN] != null ? this.state.markers[ORIGIN] : this.props.originStop
                const originAddress = this.state.originAddress !== '' ? this.state.originAddress : this.props.originStop.desc
                const destination = this.state.markers[DESTINATION] != null ? this.state.markers[DESTINATION] : this.props.destinationStop
                const destinationAddress = this.state.destinationAddress !== '' ? this.state.destinationAddress : this.props.destinationStop.desc
                const requestObj = factory.createTripRequest({
                    origin,
                    originAddress,
                    destination,
                    destinationAddress,
                    isRequestByDropOff: this.state.isRequestByDropOff,
                    numPassengers: parseInt(this.state.passengers),
                    requestPickUpStartTime: this.state.pickupTime,
                    customerName: customerName.trim(),
                    hasWheelChair: this.state.wheelchair,
                    babyCart: this.state.babyCart,
                    luggage: parseInt(this.state.luggage),
                    customerId,
                    customerDateOfBirth,
                    serviceName,
                    isOnRelatedCustomerBehalf: this.state.isOnRelatedCustomerBehalf,
                    relatedCustomer: this.state.relatedCustomer,
                    isReturn: this.state.isReturn,
                    originalTripId: this.state.originalTripId
                })
                this.props.getTripServices(requestObj, customerId);
            }
        }
    }

    render() {
        const { t, customerReducer } = this.props;
        const favouriteAddresses = customerReducer?.currentUser?.favouriteAddresses?.map(x => {
            return {
                description: x.addressPosition.address,
                type: 'favouriteAddress',
                matched_substrings: [
                    { length: x.addressPosition.address.length, offset: 0 },
                ],
                structured_formatting: {
                    main_text: x.addressPosition.address.split(',')[0].trim(),
                    main_text_matched_substrings: [
                        { length: x.addressPosition.address.length, offset: 0 },
                    ],
                },
            }
        }) || []
        const relatedCustomers = customerReducer.currentUser?.relatedCustomers.map(x => {
            return {
                description: x.name + ' ' + x.surname1,
                relatedCustomerObject: x,
                type: 'relatedCustomer',
                matched_substrings: [
                    { length: (x.name + ' ' + x.surname1).length, offset: 0 },
                ],
                structured_formatting: {
                    main_text: (x.name + ' ' + x.surname1),
                    main_text_matched_substrings: [
                        { length: (x.name + ' ' + x.surname1).length, offset: 0 },
                    ],
                },
            }
        }) || []


        const CustomTextField = forwardRef(
            ({ value, onClick }, ref) => (
                <TextField
                    variant="outlined"
                    label={t('form.requestTripForm.dateTime')}
                    value={value}
                    onClick={onClick}
                    ref={ref}
                    style={{ width: '100%' }}
                    fullWidth
                    error={(this.state.pickupTime == null && this.state.ticked == true) ? true : false}
                />
            ),
        );
        return (
            <React.Fragment>
                <Grid container justifyContent='center' spacing={3} style={{ paddingTop: 20 }}>
                    <Grid item xl={7} md={9} sm={12} id='origin'>
                        <InputAutocomplete
                            id='origin'
                            label={t('form.requestTripForm.origin')}
                            editable={this.props.editable}
                            error={(this.state.markers[ORIGIN] == null && this.state.ticked == true) ? true : false}
                            setAddress={(address) => this.geoCodeAddress(address, ORIGIN)}
                            value={this.state.originAddress}
                            optionsDefault={
                                this.props.customerReducer.currentUser?.addressPosition?.address
                                    ? [
                                        {
                                            'description': this.props.customerReducer.currentUser?.addressPosition?.address,
                                            'type': 'customerAddress',
                                            'matched_substrings': [{ length: this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].length, offset: 0 }],
                                            'structured_formatting': {
                                                'main_text': this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].trim(),
                                                'main_text_matched_substrings': [{ length: this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].length, offset: 0 }]
                                            }
                                        }
                                    ]
                                        .concat(favouriteAddresses)
                                    : favouriteAddresses || null
                            }
                        />
                    </Grid>
                    <Grid item xl={7} md={9} sm={12} id='destination'>
                        <InputAutocomplete
                            id='destination'
                            label={t('form.requestTripForm.destination')}
                            editable={this.props.editable}
                            error={(this.state.markers[DESTINATION] == null && this.state.ticked == true) ? true : false}
                            setAddress={(address) => this.geoCodeAddress(address, DESTINATION)}
                            value={this.state.destinationAddress}
                            optionsDefault={
                                this.props.customerReducer.currentUser?.addressPosition?.address
                                    ? [
                                        {
                                            'description': this.props.customerReducer.currentUser?.addressPosition?.address,
                                            'type': 'customerAddress',
                                            'matched_substrings': [{ length: this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].length, offset: 0 }],
                                            'structured_formatting': {
                                                'main_text': this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].trim(),
                                                'main_text_matched_substrings': [{ length: this.props.customerReducer.currentUser?.addressPosition?.address.split(',')[0].length, offset: 0 }]
                                            }
                                        }
                                    ]
                                        .concat(favouriteAddresses).concat(this.state.destinationsZones)
                                    : favouriteAddresses.concat(this.state.destinationsZones) || null
                            }
                        />
                    </Grid>
                    <Grid item xl={7} md={9} sm={12}>
                        <SelectRequestType
                            editable={this.props.editable}
                            isRequestByDropOff={this.state.isRequestByDropOff}
                            onChange={(value) => this.setState({ isRequestByDropOff: value })}
                        />
                    </Grid>
                    <Grid item xl={7} md={9} sm={12} id='datetime'>
                        <DatePicker
                            wrapperClassName='datepicker100'
                            popperPlacement="top-end"
                            selected={this.state.pickupTime}
                            onChange={date => {
                                this.setState({ pickupTime: date })
                            }}
                            showTimeSelect
                            disabled={this.props.tripReducer.isModifyTrip == true ? false : !this.props.editable}
                            locale={i18next.language}
                            timeFormat="p"
                            timeIntervals={5}
                            maxDate={setYear(new Date(), 9999)}
                            minDate={new Date()}
                            name="pickupTime"
                            timeCaption={this.state.timeText}
                            dateFormat="dd/MM/yyyy HH:mm"
                            customInput={<CustomTextField />}
                        />
                    </Grid>
                    <Grid item xl={7} md={9} sm={12}>
                        <TextField
                            InputLabelProps={{ style: { pointerEvents: "auto" }, shrink: true }}
                            value={this.state.passengers}
                            label={t('form.requestTripForm.passengers')}
                            name="quantityPassengers"
                            //disabled={tripReducer.serviceSelected == null ? false : true}
                            inputProps={
                                { min: 1 }
                            }
                            error={((this.state.passengers == "" || this.state.passengers < 1) && this.state.ticked == true) ? true : false}
                            fullWidth
                            type={'number'}
                            onChange={(e) => this.setState({ passengers: e.target.value })}
                            disabled={!this.props.editable}
                        />
                    </Grid>
                    <Grid item xl={7} md={9} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                InputLabelProps={{ style: { pointerEvents: "auto" }, shrink: true }}
                                value={this.state.luggage}
                                label={t('form.requestTripForm.luggage')}
                                name="quantityLuggage"
                                //disabled={tripReducer.serviceSelected == null ? false : true}
                                inputProps={
                                    { min: 0 }
                                }
                                error={((this.state.luggage === "" || this.state.luggage < 0) && this.state.ticked == true) ? true : false}
                                fullWidth
                                type={'number'}
                                onChange={(e) => this.setState({ luggage: e.target.value })}
                                disabled={!this.props.editable}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xl={7} md={9} sm={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.wheelchair}
                                    onChange={(e) => this.setState({ wheelchair: e.target.checked, userWheelChairSelection: e.target.checked })}
                                    color="primary"
                                />
                            }
                            label={t('form.requestTripForm.wheelChair')}
                            disabled={!this.props.editable}
                        />
                    </Grid>
                    {/* START DEPENDENTS TRIP */}
                    <Grid item xl={7} md={9} sm={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.isOnRelatedCustomerBehalf}
                                    onChange={(e) => this.setState({ isOnRelatedCustomerBehalf: e.target.checked })}
                                    name="checked"
                                    color="primary"
                                />
                            }
                            label={t('form.requestTripForm.tripOnBehalfOfDependantPerson')}
                            disabled={!this.props.editable}
                        />
                    </Grid>
                    {this.state.isOnRelatedCustomerBehalf == true && (
                        <InputAutocompleteRelated
                            id='relatedCustomer'
                            label={t('form.requestTripForm.nameOfDependant')}
                            editable={this.props.editable}
                            onC
                            error={(this.state.relatedCustomer == null && this.state.ticked == true) ? true : false}
                            setValue={(value) => {
                                if (value == null) {
                                    this.setState({
                                        ...this.state,
                                        relatedCustomer: null,
                                        wheelchair: this.state.userWheelChairSelection,
                                    })
                                    return
                                }
                                this.setState({
                                    wheelchair: value.relatedCustomerObject.hasWheelChair,
                                    relatedCustomer: {
                                        "id": value.relatedCustomerObject.id,
                                        "name": value.relatedCustomerObject.name + ' ' + value.relatedCustomerObject.surname1,
                                        "dateOfBirth": value.relatedCustomerObject.birthDate
                                    }
                                })
                            }
                            }
                            value={this.state.relatedCustomer?.name || ''}
                            optionsDefault={
                                this.props.customerReducer?.relatedCustomers
                                    ? relatedCustomers
                                    : relatedCustomers || null
                            }
                        />
                    )}
                    {/* END DEPENDENTS TRIP */}
                    <Grid item xl={7} md={7} sm={12} >
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                            onClick={this.handleRequestTrip}
                            size="medium"
                            disabled={this.props.tripReducer.isModifyTrip == true ? false : !this.props.editable}>
                            {t('form.requestTripForm.requestTrip')}
                        </Button>
                    </Grid>
                    {this.props.tripReducer.repeatTrip == true &&
                        <Grid item xl={7} md={7} sm={12} >
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => history.goBack()}
                                size="medium">
                                {t('form.requestTripForm.back')}
                            </Button>
                        </Grid>
                    }
                    {this.props.editable == false &&
                        <Grid item xl={7} md={7} sm={12} >
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => {
                                    this.props.cleanRepeatTrip();
                                    window.location.reload()
                                }}
                                size="medium">
                                {t('form.requestTripForm.cancelProcess')}
                            </Button>
                        </Grid>
                    }
                    <WaitingScreen
                        message={t('form.requestTripForm.sendingRequest')}
                        open={this.props.tripReducer.isSubmitting == true ? true : false}
                    />
                </Grid>

                <ModalSelectServices
                    setStops={this.props.setStops}
                    open={this.props.tripReducer.modalSelectedService || false}
                    handClose={this.props.handClose}
                    //insert={this.props.insert}
                    requestTrip={this.props.requestTrip}
                    t={t}
                    tripReducer={this.props.tripReducer}
                />
                <ModalExpeditions
                    t={t}
                    tripReducer={this.props.tripReducer}
                    daysOfWeek={this.props.tripReducer.weekDays}
                    open={this.props.tripReducer.modalExpeditions}
                    handClose={this.props.closeModalExpeditions}
                />

                <AlertDialog
                    title={""}
                    open={this.props.tripReducer.modalMessage}
                    content={() => this.props.tripReducer.message || ''}
                    onClickAccept={this.props.closeModalRequestTrip}
                />
            </React.Fragment>

        )
    }
}

export default RequestTripForm;