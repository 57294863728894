import React from 'react';
import { Grid, Button, Box, Tooltip, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Spinner from '../../shared/spinner';
import moment from 'moment';
import UserTariffTypology from '../userTariff';
import WarningIcon from '@material-ui/icons/Warning'
import { SERVICES_TYPE, TripStatus } from '../../../constants/types';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom'
import { BackButton } from '../../shared/back';
import { TripProperty } from './details';
import { history } from '../../../helpers/history';
import { tripActions } from '../../../redux/actions/trip_actions';
import AlertDialog from '../../alert-dialog';
import { multipleTripsActions } from '../../../redux/actions/multipleTrips_action';
import { ResumeTripActions } from './components/buttons';

class ResumeTripPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clickButton: false,
            originAddress: '',
            destinationAddress: '',
            pickupTime: '',
            dropOffTime: '',
            resolve: false,
            clickInfoPrice: false,
            preventExitWithTripPending: false
        }
        this.handleClickInfo = this.handleClickInfo.bind(this)
        this.onClickBack = this.onClickBack.bind(this)
        this.onRejectClick = this.onRejectClick.bind(this)
        this.onRepeatTrip = this.onRepeatTrip.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.tripReducer.trip == null) return
        if (this.props.tripReducer.trip == prevProps.tripReducer.trip) return

        this.coordsToAddress()
    }

    componentDidMount() {
        if (this.props.tripReducer.trip == null) return
        this.coordsToAddress();
    }

    componentWillUnmount() {
        const { trip, showResume } = this.props.tripReducer

        this.props.dispatch(tripActions.setTrip(null))

        if (trip != null && showResume == false) {
            if (trip != null) {
                if (trip.status == TripStatus.Available && !this.state.clickButton) {
                    this.props.dispatch(tripActions.setTripToRejectedByUser(trip.tripId))
                }
            }
        }
    }

    async coordsToAddress() {
        let originAddress = this.props.tripReducer.trip.pickUpLocation.address;
        let destinationAddress = this.props.tripReducer.trip.dropOffLocation.address;
        let pickupTimeUTC = moment.utc(this.props.tripReducer.trip.schedulePickUpTime).toDate();
        let pickupTime = moment(pickupTimeUTC).local().format('DD/MM/YYYY HH:mm');
        let dropOffTimeUTC = moment.utc(this.props.tripReducer.trip.scheduleDropOffTime).toDate();
        let dropOffTime = moment(dropOffTimeUTC).local().format('DD/MM/YYYY HH:mm');
        this.setState({
            originAddress,
            destinationAddress,
            pickupTime,
            dropOffTime,
            resolve: true
        })
    }

    acceptTrip = (tripId) => {
        const { tripReducer, multipleTripReducer } = this.props
        const { trip } = tripReducer

        if (!multipleTripReducer.areInReturnProcess) {
            if (tripReducer.isModifyTrip == true) {
                let newTripId = tripId;
                let oldTripId = this.props.tripReducer.modifyTripData.tripId;
                this.props.dispatch(tripActions.acceptModifyTrip(newTripId, oldTripId));
            } else {
                this.props.dispatch(tripActions.setTripToAcceptedByUser(tripId));
            }
        } else {
            this.props.dispatch(multipleTripsActions.acceptTripReturn(trip, multipleTripReducer.selectedTripsToReturn.filter(item => item.checked)))
        }

        this.setState({
            clickButton: true,
        })
    }

    rejectTrip = (tripId) => {
        this.props.dispatch(tripActions.setTripToRejectedByUser(tripId));
        history.push('/home')
    }

    handleClickInfo() {
        this.setState({
            clickInfoPrice: !this.state.clickInfoPrice
        })
    }

    onRepeatTrip() {
        const { trip } = this.props.tripReducer
        const newTrip = {
            ...trip,
            pickUpLocation: trip.dropOffLocation,
            dropOffLocation: trip.pickUpLocation,
        }

        let relatedCustomer = null
        for (const element of this.props.customerReducer.relatedCustomers) {
            if (element.id === newTrip.relatedCustomerId) {
                relatedCustomer = {
                    id: newTrip.relatedCustomerId,
                    name: newTrip.relatedCustomerName,
                    dateOfBirth: element.birthDate
                }
            }
        }
        const newCurrentTrip = { ...newTrip, relatedCustomer }

        this.props.dispatch(tripActions.repeatTrip(newCurrentTrip, true))
        this.props.dispatch(tripActions.cleanTrip())
        history.push('/home')
    }

    onClickBack() {
        const { multipleTripReducer } = this.props
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)
        if (hasTripWithError) {
            this.setState({ preventExitWithTripPending: true })
        } else
            history.goBack()
    }

    handleOnClickPlan(trip) {
        this.props.dispatch(multipleTripsActions.setOpenModalSelectFrecuency(true, trip.tripId, trip.requestPickUpStartTime, trip.serviceType))
    }

    onRejectClick(id) {
        this.props.rejectTrip(id)
        this.setState({
            clickButton: true,
        })
    }
    render() {
        const t = i18next.t.bind(i18next)
        const { tripReducer, multipleTripReducer } = this.props

        const trip = tripReducer.trip
        const executeMultipleTrips = multipleTripReducer.executeMultipleTrips

        const columns = {
            passenger: [2, 3],
            origin: [2, 3],
            destination: [2, 3],
            date: [2, 3],
            actions: 3
        }
        const index = executeMultipleTrips | 0
        const hasTripWithError = multipleTripReducer.results.some(item => item.hasErrors)

        if (this.state.resolve) {
            return (
                <div>
                    <Prompt
                        when={hasTripWithError && !this.state.preventExitWithTripPending}
                        message={(location, action) => {
                            return t('form.multipleBook.pendingTripsConfirm')
                        }}
                    />
                    <Grid
                        container
                        spacing={1}
                        style={{
                            paddingBottom: 20,
                            paddingLeft: 20,
                        }}>
                        <Grid item xs={12} sm={12}>
                            <h2>
                                <BackButton onClick={this.onClickBack} />
                                {!multipleTripReducer.areInReturnProcess ? t('form.resumeService.title') : t('form.resumeService.requestReturnTitle')}
                            </h2>
                        </Grid>
                        {
                            trip && (
                                <Grid container>
                                    <Grid item md={columns.passenger[index]} sm={3} xs={12}>
                                        <TripProperty isBold>{t('form.resumeService.customer')}</TripProperty>
                                        <TripProperty>{trip.customerName}</TripProperty>
                                        <TripProperty isBold>
                                            {t('form.resumeService.price')}
                                        </TripProperty>
                                        <TripProperty>
                                            <Box display={'flex'} alignContent={'center'}>
                                                {trip.price?.amount}€
                                                {trip.serviceType != SERVICES_TYPE.regularWithSchedule ? (
                                                    <IconButton style={{ padding: 0, fontSize: 'inherit' }}>
                                                        <InfoIcon onClick={this.handleClickInfo} fontSize='inherit' />
                                                    </IconButton>
                                                ) : null}
                                            </Box>
                                        </TripProperty>
                                    </Grid>
                                    <Grid item md={columns.origin[index]} sm={6} xs={12}>
                                        <TripProperty isBold>{t('form.resumeService.origin')}:</TripProperty>
                                        <TripProperty>
                                            {this.state.originAddress}
                                        </TripProperty>
                                        {trip.pickUpStopName ? (
                                            <>
                                                <TripProperty isBold>
                                                    {t('form.resumeService.pickUpStopName')}
                                                </TripProperty>
                                                <TripProperty>{trip.pickUpStopName}</TripProperty>
                                            </>
                                        ) : null}
                                    </Grid>
                                    <Grid item md={columns.destination[index]} sm={6} xs={12}>
                                        <TripProperty isBold>{t('form.resumeService.destination')}:</TripProperty>
                                        <TripProperty>
                                            {this.state.destinationAddress}
                                        </TripProperty>
                                        {trip.dropOffStopName ? (
                                            <>
                                                <TripProperty isBold>
                                                    {t('form.resumeService.dropOffStopName')}
                                                </TripProperty>
                                                <TripProperty>{trip.dropOffStopName}</TripProperty>
                                            </>
                                        ) : null}
                                    </Grid>
                                    <Grid item md={columns.date[index]} sm={6} xs={12}>
                                        <TripProperty isBold>{t('form.resumeService.dateTime')}</TripProperty>
                                        <TripProperty>
                                            {`${t('form.resumeService.pickUpTime')}:  ${this.state.pickupTime}`}
                                            {trip.status === TripStatus.Available && trip.isAlternateSolution ? (
                                                <span>
                                                    <Tooltip title={t('services.trips.warningAlternateSolution')}>
                                                        <WarningIcon />
                                                    </Tooltip>
                                                </span>
                                            ) : null}
                                        </TripProperty>
                                        <TripProperty>
                                            {`${t('form.resumeService.dropOffTime')}:  ${this.state.dropOffTime}`}
                                        </TripProperty>
                                    </Grid>
                                    <ResumeTripActions
                                        handleOnClickPlan={() => this.handleOnClickPlan(this.props.tripReducer.trip)}
                                        onAcceptClick={this.acceptTrip}
                                        onRejectClick={this.onRejectClick}
                                        onRepeatTrip={this.onRepeatTrip}
                                        trip={trip}
                                    />
                                </Grid>
                            )
                        }

                    </Grid>
                    <AlertDialog
                        open={this.state.clickInfoPrice}
                        maxWidth={'md'}
                        title={""}
                        fullWidth
                        onClose={this.handleClickInfo}
                        onClickAccept={this.handleClickInfo}
                        content={
                            () => <UserTariffTypology
                                tariff={trip.userTariff}
                                t={t}
                            />
                        }
                    />
                    <AlertDialog
                        title={t('form.multipleBook.pendingTripsConfirm')}
                        open={this.state.preventExitWithTripPending}
                        onClose={() => this.setState({ preventExitWithTripPending: false })}
                        onClickCancel={() => this.setState({ preventExitWithTripPending: false })}
                        onClickAccept={() => history.goBack()}
                    />
                </div>

            )
        } else {
            return (
                <Spinner loading={true} />
            )
        }
    }
}

export default withTranslation('common')(ResumeTripPage);

