import React from 'react';
import Spinner from '../shared/spinner';
//import BookingCard from './bookingCard';
import MaterialTableExtend from '../material-table';
import AlertDialog from '../alert-dialog';
import { STATES } from '../../constants/states_trip';
import { withTranslation } from 'react-i18next'
import { history } from '../../helpers/history';
import GroupIcon from '@material-ui/icons/Group';
import { Tooltip } from '@material-ui/core'

class BookingsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            origin: '',
            selectedRow: null,
            openModal: false,
            tripToDelete: null
        }
    }

    componentDidMount() {
        //fetch all bookings from this customer
        this.props.getCustomerProfile();
        this.props.getTripsFromCustomer();
        this.props.getTripStatuses()
    }

    onClickAccept = () => {
        this.setState({ openModal: !this.state.openModal });
        //cancelar viatge
        if (this.state.tripToDelete != null) {
            this.props.setTripToCancelledByUser(this.state.tripToDelete);
        }
    }

    onClickCancel = () => {
        this.setState({ openModal: !this.state.openModal })
    }

    onClickDetail(trip) {
        const params = new URLSearchParams({ id: trip.tripId });

        if (trip.isMultipleRequest) {
            params.append('originalId', trip.originalTripId)
        }

        history.replace({ pathname: '/resumeTrip', search: params.toString() });
    }

    getStatus = (status) => {
        const { typesTrips } = this.props.tripReducer
        return typesTrips.find(item => item.id == status)?.name
    }

    getTypes = () => {
        const { typesTrips } = this.props.tripReducer
        let result = {}
        typesTrips.forEach(element => {
            result[element.id] = element.name
        });
        return result
    }

    setRepeatTrip = (rowData) => {
        let relatedCustomer = null;
        for (const element of this.props.customerReducer.relatedCustomers) {
            if (element.id == rowData.relatedCustomerId) {
                relatedCustomer = {
                    'id': rowData.relatedCustomerId,
                    'name': rowData.relatedCustomerName,
                    'dateOfBirth': element.birthDate
                }
            }
        }
        let newRepeatDataObject = { ...rowData, relatedCustomer };
        //console.log("newRepeatDataObject => ", newRepeatDataObject);
        this.props.repeatTrip(newRepeatDataObject, false)
        history.push('/home', 'repeatTrip')
    }

    setModifyTrip = (rowData) => {
        let relatedCustomer = null;
        if (this.props.customerReducer != null) {
            this.props.customerReducer.relatedCustomers.forEach(element => {
                //console.log("element => ", element)
                if (element.id == rowData.relatedCustomerId) {
                    relatedCustomer = {
                        'id': rowData.relatedCustomerId,
                        'name': rowData.relatedCustomerName,
                        'dateOfBirth': element.birthDate
                    }
                }
            });
        }
        let newCurrentTrip = { ...rowData, relatedCustomer };
        this.props.setModifyTripData(newCurrentTrip);
        history.push('/home', 'modifyTrip')
    }

    render() {
        const { t, tripReducer } = this.props;
        return (
            <React.Fragment>
                {tripReducer.isSubmitting == false ?
                    <React.Fragment>
                        <MaterialTableExtend
                            title={t('bookings.title')}
                            //selection={false}
                            pageSize={10}
                            columns={[
                                {
                                    title: t('bookings.columns.status'), field: 'status', /*filtering: true,*/
                                    render: rowData => {
                                        if (rowData.isOnRelatedCustomerBehalf == true) {
                                            return (
                                                <>
                                                    <Tooltip
                                                        title={t('bookings.columns.relatedTrip') + rowData.relatedCustomerName}
                                                        arrow
                                                    >
                                                        <GroupIcon
                                                            color="primary"
                                                            style={{
                                                                fontSize: 15,
                                                                marginRight: 5,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {this.getStatus(rowData.status)}
                                                </>
                                            )
                                        } else {
                                            return (
                                                this.getStatus(rowData.status)
                                            )

                                        }

                                    },
                                    //defaultFilter: [],
                                    lookup: this.getTypes()
                                },
                                { title: t('bookings.columns.pickUpTime'), field: 'schedulePickUpTime', type: 'datetime', /*filtering: false,*/ defaultSort: 'desc' },
                                { title: t('bookings.columns.originAddress'), field: 'pickUpLocation.address', type: 'string', render: rowData => <p>{rowData.pickUpLocation != null ? rowData.pickUpLocation.address : ''}</p>, sorting: true, /*filtering: false*/ },
                                { title: t('bookings.columns.dropOffTime'), field: 'status' == STATES.Finished.id ? 'actualDropOffTime' : 'scheduleDropOffTime', type: 'datetime', /*filtering: false*/ },
                                { title: t('bookings.columns.destinationAddress'), field: 'dropOffLocation.address', render: rowData => <p>{rowData.dropOffLocation != null ? rowData.dropOffLocation.address : ''}</p>, sorting: false, /*filtering: false*/ },
                                { title: t('bookings.columns.numPassengers'), field: 'numPassengers', /*filtering: false*/ },
                            ]}
                            //data={tripReducer.bookings}
                            data={this.props.tripReducer.bookings}
                            actionsColumnIndex={7}
                            actions={[
                                rowData => ({
                                    icon: 'view_list',
                                    tooltip: t('bookings.showDetails'),
                                    onClick: (event, rowData) => this.onClickDetail(rowData),
                                    disabled: rowData.pickUpLocation == null || rowData.dropOffLocation == null
                                }),
                                rowData => ({
                                    icon: 'replay',
                                    tooltip: t('bookings.repeatTrip'),
                                    onClick: (event, rowData) => this.setRepeatTrip(rowData),
                                    disabled: !rowData
                                }),
                                rowData => ({
                                    icon: 'edit',
                                    tooltip: t('bookings.modifyTrip'),
                                    onClick: (event, rowData) => this.setModifyTrip(rowData),
                                    disabled: !rowData.isModifiable
                                }),
                                rowData => ({
                                    icon: 'cancel',
                                    //tooltip: <p style={{ textDecorationLine: 'line-through'}}>{t('bookings.cancelTrip')}</p>,
                                    tooltip: t('bookings.cancelTrip'),
                                    onClick: (event, rowData) => this.setState({ openModal: true, tripToDelete: rowData.tripId }),
                                    // disabled: rowData.status == STATES.Cancelled.id || rowData.status == STATES.CancelledByDriver.id || rowData.status == STATES.CancelledByCustomerService ||
                                    //     rowData.status == STATES.Finished.id || rowData.status == STATES.FinishedBySystem.id
                                    disabled: !rowData.isCancellable
                                }),
                            ]}
                        />
                        <AlertDialog
                            open={this.state.openModal}
                            title={t('bookings.cancelBooking')}
                            onClickAccept={() => this.onClickAccept()}
                            onClickCancel={() => this.onClickCancel()}
                        />
                    </React.Fragment>
                    :
                    <Spinner
                        loading={tripReducer.isSubmitting}
                    />
                }
            </React.Fragment>
        );
    }
}

export default withTranslation('common')(BookingsView);