import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import DashBoard from '../dashboard/index';
import BookingsView from './page';
import { tripActions } from '../../redux/actions/trip_actions';
import { userActions } from '../../redux/actions/user_actions';
import { multipleTripsActions } from '../../redux/actions/multipleTrips_action'

class Bookings extends React.Component {
    constructor(props) {
        super();
        this.state = {}
    }

    render() {
        return (
            <DashBoard
                component={
                    <BookingsView {...this.props} />
                }
            />
        );
    }

}

function mapState(state) {
    const { customerReducer, tripReducer } = state;
    return { customerReducer, tripReducer };
}

const actionCreators = {
    getCustomerProfile: userActions.getCustomerProfile,
    getTripsFromCustomer: tripActions.getTripsFromCustomer,
    setTrip: tripActions.setTrip,
    setTripToCancelledByUser: tripActions.setTripToCancelledByUser,
    repeatTrip: tripActions.repeatTrip,
    setModifyTripData: tripActions.setModifyTripData,
    getTripStatuses: tripActions.getTripStatuses,
    getTripsFromMultipleRequest: multipleTripsActions.getTripsFromMultipleRequest
}

export default compose(withTranslation('common'), connect(mapState, actionCreators))(Bookings);
