import moment from "moment"
import { multipleTripConstants } from "../../constants/multipleTrip_constants"
import { tripConstants } from "../../constants/trip_constants"

let initialState = {
    openModalSelectFrecuency: false,
    openModalShowResults: false,
    executeMultipleTrips: false,
    areInReturnProcess: false,
    results: [],
    pending: false,
    tripIdToPlanOutbound: null,
    tripIdToPlanReturn: null,
    selectedTripsToReturn: [],
    requestPickUpStartTime: null
}

export function multipleTripReducer(state = initialState, action) {
    switch (action.type) {
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_SUCCESS:
            return {
                ...state,
                pending: false,
                executeMultipleTrips: true,
                openModalSelectFrecuency: false,
                results: action.results,
                selectedTripsToReturn: action.results.map((item) => {
                    return {
                        id: item.tripId,
                        checked: false
                    }
                })
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case multipleTripConstants.GET_TRIPS_MULTIPLE_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.GET_TRIPS_MULTIPLE_SUCCESS:
            return {
                ...state,
                pending: false,
                executeMultipleTrips: true,
                openModalSelectFrecuency: false,
                results: action.results,
                selectedTripsToReturn: action.results.map((item) => {
                    return {
                        id: item.tripId,
                        checked: false,
                        schedulePickUpTime: item.requestPickUpTime
                    }
                })
            }
        case multipleTripConstants.UPDATE_SELECTED_TRIPS:
            return {
                ...state,
                selectedTripsToReturn: action.selectedValues
            }
        case multipleTripConstants.OPEN_MODAL_SELECT_FRECUENCY:
            return {
                ...state,
                executeMultipleTrips: false,
                openModalSelectFrecuency: action.value,
                tripIdToPlanOutbound: action.tripId,
                requestPickUpStartTime: action.requestPickUpStartTime,
                serviceType: action.serviceType,
                maxRequestTripDays: action.maxRequestTimeDays
            }
        case multipleTripConstants.SHOW_MODAL_DETAILS:
            return {
                ...state,
                openModalShowResults: action.value
            }
        case multipleTripConstants.SET_TRIP_ID_PLAN:
            return {
                ...state,
                tripIdToPlanOutbound: action.value
            }
        case multipleTripConstants.UPDATE_LIST_TRIPS:
            return {
                ...state,
                pending: false,
                results: state.results.filter(item => item.tripId != action.tripId)
            }
        case tripConstants.SET_TRIP_SUCCESS:
            return {
                ...state,
            }
        case tripConstants.GET_TRIP_SERVICES_REQUEST:
            return {
                ...state,
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_SUCCESS:
            return {
                ...state,
                pending: false,
                results: state.results.map((item) => item.tripId === action.tripIdToRetry ? action.result : item)
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETRY_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case multipleTripConstants.CANCEL_TRIP_REQUEST:
            return {
                ...state,
                pending: true
            }
        case multipleTripConstants.CANCEL_TRIP_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case multipleTripConstants.UPDATE_SCHEDULE_RETURN_PROCESS:
            return {
                ...state,
                areInReturnProcess: action.value,
                outboundTripId: action.value ? state.outboundTripId : null,
                selectedTripsToReturn: action.value ? state.selectedTripsToReturn : state.selectedTripsToReturn.map((item) => {
                    return {
                        id: item.id,
                        checked: false,
                        schedulePickUpTime: item.schedulePickUpTime
                    }
                })
            }
        case tripConstants.SET_REQUEST_TRIP_INFO:
            return {
                ...state,
                outboundTripId: action.outboundTripId
            }
        case multipleTripConstants.UPDATE_TRIPS_TO_LIST_EXIST:
            const newResults = state.results.concat(action.array).sort((a, b) => moment(a.requestPickUpTime).unix() - moment(b.requestPickUpTime).unix())
            return {
                ...state,
                results: newResults
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_SUCCESS:
            return {
                ...state,
                pending: false,
                openModalSelectFrecuency: false
            }
        case multipleTripConstants.PROCCESS_MULTIPLE_TRIP_RETURN_FAILURE:
            return {
                ...state,
                pending: false,
                openModalSelectFrecuency: false,
                error: action
            }
        case tripConstants.GET_TRIP_SERVICES_FAILURE:
            return {
                ...state,
                areInReturnProcess: false
            }

        case multipleTripConstants.GET_TRIPS_MULTIPLE_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state
    }
}