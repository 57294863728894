import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tripActions } from "../../../../redux/actions/trip_actions";
import { multipleTripsActions } from "../../../../redux/actions/multipleTrips_action";
import { outboundHasReturn } from "../../tools";

export const useSelectTrips = ({ disableCheck }) => {

    const tripToPreview = useSelector((state) => state.tripReducer.trip)
    const listOfTrips = useSelector((state) => state.multipleTripReducer.results)
    const selectedTripsToReturn = useSelector((state) => state.multipleTripReducer.selectedTripsToReturn)
    const [selectedAll, setSelectedAll] = useState(selectedTripsToReturn.every((item) => item.checked))

    const dispatch = useDispatch()

    const changeTripSelectedToPreview = useCallback((trip) => {
        dispatch(tripActions.setTrip(trip))
    }, [dispatch])

    const handleChangeSelectTrip = (event, trip) => {
        const newSelectedTrips = selectedTripsToReturn.map((item) => item.id == trip.tripId ? { id: trip.tripId, checked: event.target.checked, schedulePickUpTime: trip.schedulePickUpTime } : item)
        dispatch(multipleTripsActions.updateSelectedTripsToReturn(newSelectedTrips))
    };


    const selectAll = useCallback(() => {
        const allTripsCanSelect = listOfTrips.filter(item => !disableCheck(item))
        const newSelectedTrips = allTripsCanSelect.map((item) => ({ id: item.tripResponse.tripId, checked: !selectedAll, schedulePickUpTime: item.tripResponse.schedulePickUpTime }))
        dispatch(multipleTripsActions.updateSelectedTripsToReturn(newSelectedTrips))
        setSelectedAll((prevValue) => !prevValue)
    }, [disableCheck, dispatch, listOfTrips, selectedAll])

    const canScheduleSomeTrip = useMemo(() => {
        return listOfTrips.some((item) => (!outboundHasReturn(item.tripResponse, listOfTrips) && !item.tripResponse?.isReturn))
    }, [listOfTrips])


    const canScheduleReturn = useMemo(() => {
        return selectedTripsToReturn.some(item => item.checked)
    }, [selectedTripsToReturn])

    return {
        selectedTrips: selectedTripsToReturn,
        handleChangeSelectTrip,
        canScheduleReturn,
        canScheduleSomeTrip,
        selectAll,
        selectedAll,
        changeTripSelectedToPreview,
        tripToPreview
    }
}